import React from "react"
import {Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import AboutPageHeader from "../components/aboutpageheader"
import * as aboutstyles from "../styles/pages/about.module.css"
import * as styles from "../styles/pages/about/about.module.css"

const imgbanner = "../images/about/banner.jpg"
const imgourproducts = "../images/about/ourproducts.jpg"

const AboutPage = () => (
	<Layout activePath='about' pageTitle="About Us" pageDescription="Pacific Paint (BOYSEN) Philippines, Inc. continues to be the dominant architectural coatings and construction chemicals company committed to products and service excellence for our customers.">
		<AboutPageHeader activemenu="about" />
		<div className={aboutstyles.banner}>
			<StaticImage layout={"fullWidth"}  src={imgbanner} alt={"About Us"} />
		</div>
		<div className={aboutstyles.bghighlight}>
			<div className={"text-centered "+aboutstyles.content+" "+styles.ourvision}>
				<h2 className={"font-style-bold font-size-medium "+aboutstyles.contenttitle}>OUR VISION</h2>
				<div className={aboutstyles.contenttext}>
					Pacific Paint (<span className="nobr">BOYSEN<span className={"registeredicon"}>&reg;</span></span>) Philippines, Inc. continues to be the dominant architectural coatings and construction chemicals company committed to products and service excellence for our customers.
				</div>
				<div className={aboutstyles.contenttext}>
					We are globally recognized as the leader for innovative solutions, driven and dedicated to the development of our people, focusing on sustainable growth and profitability while uplifting the quality of life of all stakeholders, the society, and the preservation of the environment.
				</div>
			</div>
			<div className={aboutstyles.content+" "+styles.ourmission}>
				<h2 className={"font-style-bold font-size-medium "+aboutstyles.contenttitle+" text-centered"}>OUR MISSION</h2>
				<div className={aboutstyles.contenttext}>
					We will strengthen our market leadership:
				</div>
				<ul>
					<li>By continuously innovating to add value</li>
					<li>By deepening customer loyalty with the best value paint and construction chemical products and services</li>
					<li>By fostering win-win partnerships with our stakeholders</li>
					<li>By making our people the best leaders they can be while being safe and environmentally responsible in all our activities</li>
				</ul>
			</div>
		</div>
		<div className={"text-centered "+aboutstyles.content+" "+styles.ourbrands}>
				<div className={"font-style-bold font-size-medium "+aboutstyles.contenttitle}>OUR BRANDS</div>
				<div className={aboutstyles.contenttext}>
					We've got you. <span className="nobr">BOYSEN<span className={"registeredicon"}>&reg;</span></span>'s comprehensive line of products suits a wide-range of needs. Our trusted brands include Permacoat&trade;, Acrytex&trade;, Konstrukt&trade;, and more.
				</div>
				<Link className={"font-size-small "+aboutstyles.contentlink} to="/products" title="Browse our products">Browse our products &gt;</Link>
		</div>

		<div className={aboutstyles.banner+" "+styles.ourbrandsbanner}>
			<StaticImage layout={"fullWidth"}  src={imgourproducts} alt={"Our Brands"} />
		</div>
	</Layout>
)

export default AboutPage
